import router from "../../../router";
import { errorHandler, successHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";


export const getProjectDetails = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getProjectDetails(id).then(
    (response) => {
      commit("PROJECT_DETAILS", response.data);
      commit("SET_DATA_LOADER", false);
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const addProject = async ({ commit }, {title, type, level, quantity, price, startDate, endDate, userDetailId,desc }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.addProject(title, type, level, quantity, price, startDate, endDate, userDetailId,desc).then(
    () => {
      commit("SET_LOADER", false, { root: true });
      successHandler('Project Added Successfully')
      router.push('/user/all')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};

export const UpdateProject = async ({ commit }, {title, type, level, quantity, price, startDate, endDate, userDetailId,desc,id }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.UpdateProject(title, type, level, quantity, price, startDate, endDate, userDetailId,desc,id).then(
    () => {
      commit("SET_LOADER", false, { root: true });
      successHandler('Update Successfully')
      // router.push({path:'/user/project-list',query:{id}})
      router.go(-1)
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};
export const getUserProfile = async ({ commit ,dispatch},{id}) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProfile(id).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_PROFILE',response.data)
      dispatch('getUserProjectDetailsList',{id:response.data.id})
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getUserProjectDetailsList = async ({ commit },{id}) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProject(id).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_USER_FORMDATA',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
