export const SET_LOADER = (state, status) => {
  state.loader = status; 
};
export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};
export const SET_LOADER_NEW = (state, status) => {
  state.loaderNew = status;
};

export const SET_SETTING = (state, data) => {
  state.data = data;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};
export const SET_POSTS = (state, data) => {
  state.data = data;
  state.Total = data.total;
};

export const SET_POSTS2 = (state, data) => {
  state.data.push(...data);
};


export const SET_COLLECTION = (state, data) => {
  state.id=data.id;
  state.createdAt=data.createdAt;
  state.accountId=data.accountId;
  state.serialNo=data.serialNo;
  state.title=data.title;
  state.firstName=data.firstName;
  state.lastName=data.lastName;
  state.initial=data.initial;
  state.email=data.email;
  state.fatherName=data.fatherName;
  state.dob=data.dob;
  state.gender=data.gender;
  state.profession=data.profession;
  state.mailingStreet=data.mailingStreet;
  state.mailingCity=data.mailingCity;
  state.mailingPostalCode=data.mailingPostalCode;
  state.mailingCountry=data.mailingCountry;
  state.serviceProvider=data.serviceProvider;
  state.fileNo=data.fileNo;
  state.referenceNo=data.referenceNo;
  state.simNo=data.simNo;
  state.typeOfNetwork=data.typeOfNetwork;
  state.cellModelNo=data.cellModelNo;
  state.imei1=data.imei1;
  state.imei2=data.imei2;
  state.typeOfPlan=data.typeOfPlan;
  state.creditCardType=data.creditCardType;
  state.contractValue=data.contractValue;
  state.dateOfIssue=data.dateOfIssue;
  state.dateOfRenewal=data.dateOfRenewal;
  state.installment=data.installment;
  state.amountInWords=data.amountInWords;
  state.remarks=data.remarks;
  state.formFileId=data.formFileId;
};

export const SET_LOGO = (state, data) => {
  state.data.logo = data.logo;
};
export const UPDATE_STATUS = (state, data) => {
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  if(objIndex>=0){
    state.data.splice(objIndex,1)
  }
};