

<template>
  <hero-bar>Dashboard</hero-bar>
  <main-section>
    <!-- <input type="datetime-local"> -->
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-3 mb-6">
      <card-widget color="text-green-500" :icon="mdiFolderOpen" :number="storeVar.totalProject" label="Form Filling"
        class="cursor-pointer"  />
        <card-widget color="text-green-500" :icon="mdiFolderOpen" :number="storeVar.totalUser" label="Total User"
        class="cursor-pointer" @click.prevent="navigate('/user/all')" />
        <card-widget color="text-green-500" :icon="mdiFolderOpen" :number="storeVar.liveProject" label="Start Project"
        class="cursor-pointer" />
    </div>
  </main-section>
</template>
<script setup>
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import {
  mdiFolderOpen,
} from '@mdi/js'

const store = useStore()
const router = useRouter()
const storeVar = computed(() => store.state.Dashboard)
const formVar = reactive({
  dateTime: null,
})
function navigate(name) {
  router.push({ path: name })
}
onBeforeMount(() => {
  setTime()
   store.dispatch('Dashboard/dashboardCounts')
})


function setTime() {
  // Set the date we're counting down to
  var countDownDate = new Date("Aug 15, 2023 10:54:25").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    formVar.dateTime = days + "d " + hours + "h "
      + minutes + "m " + seconds + "s ";
    // If the count down is over, write some text 
    if (distance < 0) {
      clearInterval(x);
      formVar.dateTime = "EXPIRED";
    }
  }, 1000);

}
</script>