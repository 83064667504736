import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/AdminHome.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    component: Home,
  },
  {
    meta: {
      title: "Login", 
      fullScreen: true,
      requiresAuth: false,
    },
    path: "/login",
    name: "login",
    component: () => import("../views/AdminLogin.vue"),
  },

  {
    meta: {
      title: "FormFiles",
      requiresAuth: false,
    },
    path: "/form",
    name: "FormFiles Page",
    redirect: "/form/all",
    component: () => import("../views/FormFiles/FormFileIndex.vue"),
    children: [
      {
        path: "/form/all",
        name: "All Files",
        component: () => import("../views/FormFiles/FormFiles.vue"),
      },
      {
        path: "/form/collection-form",
        name: "All Form Collection",
        component: () => import("../views/FormFiles/FormCollection.vue"),
      },
      {
        path: "/form/add-collection-form",
        name: "Add Form Collection",
        component: () => import("../views/FormFiles/AddFormCollection.vue"),
      },
      {
        path: "/form/update-collection-form",
        name: "Update Form Collection",
        component: () => import("../views/FormFiles/UpdateFormCollection.vue"),
      },
    ]
  },


  {
    meta: {
      title: "User",
      requiresAuth: false,
    },
    path: "/default_user",
    name: "Default User",
    redirect: "/user/all",
    component: () => import("../views/User/UserIndex.vue"),
    children: [
      {
        path: "/user/all",
        name: "All User",
        component: () => import("../views/User/UserList.vue"),
      },
      {
        path: "/user/project-list",
        name: "Project List",
        component: () => import("../views/User/UserProjectList.vue"),
      },
      {
        path: "/user/project-details",
        name: "Project Details",
        component: () => import("../views/User/ProjectView.vue"),
      },
    ],
  },
 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth && !localStorage.getItem('accessToken')) {
		next('/login');
    return;
	} else {
		next();
    return;

	}




  // const isAuthenticated = localStorage.getItem("accessToken");

  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (isAuthenticated) {
  //     if (findRoute(to.path)) {
  //       next();
  //       return;
  //     } else {
  //       next(false);
  //       return;
  //     }
  //   } else {
  //     next("/login");
  //     return;
  //   }
  // }
  // next();
});

// function findRoute(path) {
//   let list = localStorage.getItem("permissions");
//   if (list) {
//     list = JSON.parse(list);
//   }

//   const objIndex = list.findIndex((obj) => obj.permissionEntity.url === path);
//   return objIndex >= 0 ? true : false;
// }
export default router;
