export default {
  data: [],
  user:{},
  total: 0,
  loadMore: false,
  dataLoader: false,
  loader: false,
  loaderNew: false,

  userName: null,
  userId: null,
  otpStatus: false,

  msg: null,
  status: false,

};
