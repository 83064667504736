import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import Dashboard from './plugins/Dashboard'

import './css/main.css'

/* Collapse mobile aside menu on route change */
router.beforeEach(() => {
  store.dispatch('asideMobileToggle', false)
  store.dispatch('asideLgToggle', false)
})

router.afterEach(to => {
  store.dispatch('fullScreenToggle', !!to.meta.fullScreen)
})

createApp(App).use(store).use(router).use(Dashboard).mount('#app')
