import { apiServices } from '../../../services/api.service';
import { successHandler, errorHandler } from '../../../services/_helper';
import router from '../../../router';

export const verifyUser = async ({ commit }, { userId, password }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.verifyId(userId, password).then(
		(response) => {
			localStorage.setItem('accessToken', response.data.token);
			successHandler('Login Successfully')
			router.push({ path: '/' });
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response)
		}
	);
};


export const loginHistory = async ({ commit }, { limit, offset }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.loginHistory(limit, offset).then(
		(response) => {
			commit("SET_LOADER", false, { root: true });
			commit('SET_LOAD_MORE', response.data.result.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data.result);
			}
		},
		() => {
			commit("SET_LOADER", false, { root: true });
			commit('SET_LOAD_MORE', false);
			if (+offset <= 0) {
				commit('SET_POSTS1', []);
			}
		}
	);
};

export const profile = async ({ commit }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.profile().then(
		async (response) => {
			commit("SET_PROFILE", response.data);
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			errorHandler(error.response);
			commit("SET_LOADER", false, { root: true });
		}
	);
};


export const updateProfile = async ({ commit }, { password, address, companyName, managerName, phoneNumber, website, configMail, configMailPassword, state, city, pincode, locality, country, landmark, whatsapp }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.updateProfile(password, address, companyName, managerName, phoneNumber, website, configMail, configMailPassword, state, city, pincode, locality, country, landmark, whatsapp).then(
		() => {
			// commit("SET_PROFILE_IMAGE", response.data.profile);
			commit("SET_LOADER", false, { root: true });
			successHandler("Profile updated successfully!");
		},
		(error) => {
			errorHandler(error.response);
			commit("SET_LOADER", false, { root: true });
		}
	);
};

export const profileImage = async (
	{ commit },
	{ file }
) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.profileImage(file).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			successHandler("Updated successfully");
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
export const Logout = async ({ commit }) => {
	localStorage.clear()
	commit('SET_OTP_STATUS', false);
	router.push({ path: '/login' })

};