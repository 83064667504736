import { createStore } from 'vuex'

import state from './notification/state'
import * as getters from './notification/getters'
import * as mutations from './notification/mutations'
import * as actions from './notification/actions'

import { Auth } from './modules/auth'
import { Dashboard } from './modules/dashboard'
import { Alert } from "./modules/error-success";
import { User } from './modules/user'
import { Project } from './modules/project'
import { FormFiles } from './modules/form-files'
import {FormCollection } from './modules/form-collection'

export default createStore({
  state,
  getters,
  mutations,
  actions,

  modules: {
    Auth,
    Dashboard,
    Alert,
    User,
    Project,
    FormFiles,
    FormCollection,
  }
})
