import { errorHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";

export const dashboardCounts = async ({ commit }) => {
  commit("SET_LOADER", true, { root: true });
  return await apiServices.getDashboardCounts().then(
      (response) => {
        commit("SET_LOADER", false, { root: true });
        commit("SET_DATA", response.data);
      },
      (error) => {
        commit("SET_LOADER", false, { root: true });
        commit("SET_NULL_DATA", []);
        errorHandler(error.response);
      }
    );
};

