<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import menu from "./menu.js";

import Notification from "./components/Base-Notification.vue";

const store = useStore();
const storeVar = computed(() => store.state);

const overlayClick = () => {
  store.dispatch("asideLgToggle", false);
};
</script>

<template>
  <nav-bar />
  <aside-menu :menu="menu" />
  <router-view />
  <br />
  <base-overlay
    v-show="storeVar.isAsideLgActive"
    z-index="z-30"
    @overlay-click="overlayClick"
  />
  <notification />
  <base-spinner1 v-if="storeVar.loader"></base-spinner1>
</template>
