import { apiServices } from '../../../services/api.service';
import { successHandler, errorHandler } from '../../../services/_helper';
import router from '../../../router';

export const getAll = async ({ commit }, { formFileId }) => {
	commit("SET_DATA_LOADER", true);
	await apiServices.getFormCollections(formFileId).then(
		(response) => {
			commit("SET_DATA_LOADER", false);
			commit('SET_POSTS', response.data);
		},
		(error) => {
			commit("SET_DATA_LOADER", false);
			errorHandler(error.response);
		}
	);
};

export const addFormCollection = async ({ commit, dispatch }, { serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.addFormCollection(serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			dispatch('getAll', { limit: 10, offset: 0, keyword: '', status: status })
			successHandler('Added successfully');
			router.push({ path: '/form/collection-form', query: { id: formFileId } })
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
export const updateFormCollection = async ({ commit, dispatch }, { serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId, id }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.updateFormCollection(serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId, id).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			dispatch('getAll', { limit: 10, offset: 0, keyword: '', status: status })
			successHandler('Update successfully');
			router.push({ path: '/form/collection-form', query: { id: formFileId } })
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};


export const getOneFormCollection = async ({ commit }, { id }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.getOneFormCollection(id).then(
		(response) => {
			commit('SET_COLLECTION', response.data)
			commit("SET_LOADER", false, { root: true });
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
export const deleteCollection = async ({ commit }, { id }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.deleteCollection(id).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			commit("UPDATE_STATUS", { id });
			successHandler("Delete successfully");
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
