import { errorHandler, successHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";


export const getUser = async ({ commit }, { limit, offset, search, status,role,projectStatus }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUser(limit, offset, search, status,role,projectStatus).then(
    (response) => {
      // commit("SET_DATA", response.data);
      commit("SET_DATA_LOADER", false);
      commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data);
			}
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const getUserProfile = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProfile(id).then(
    (response) => {
      commit("USER_PROFILE", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const StatusChange = async ({ commit }, { id,status }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.StatusChange(id,status).then(
    (response) => {
      commit("UPDATE_STATUS", response.data);
      commit("SET_DATA_LOADER", false);
    successHandler('Status Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const UserProfileUpdate = async ({ commit }, {id,  firstName, lastName, mobile, password,delay,level2,level3 }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.UserProfileUpdate(id,  firstName, lastName, mobile, password,delay,level2,level3).then(
    () => {
      commit("SET_DATA_LOADER", false);
      successHandler('Update Successfully')
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const addUser = async ({ commit ,state,dispatch}, { loginId,phoneNumber,password,name,email,startDate,endDate }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.addUser(loginId,phoneNumber,password,name,email,startDate,endDate).then(
    () => {
      state.addUserModal=false
      dispatch('getUser',{limit:10,offset:0,search:"",status:"ACTIVE",role:'USER',projectStatus:'ACTIVE'})
      commit("SET_LOADER", false, { root: true });
      successHandler('User Added Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};
export const updateUser = async ({ commit ,state}, { id,startTime,endTime,status }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.updateUserProjectTime(id,startTime,endTime,status).then(
    () => {
      state.updateUserModal=false
      commit("SET_LOADER", false, { root: true });
      commit("UPDATE_PROJECT_STATUS",{id,status,startTime,endTime})
      successHandler('Project Updated Successfully')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};

export const getUserProject = async ({ commit }, { limit, offset, search,status,accountId }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProject(limit, offset, search,status,accountId).then(
    (response) => {
      commit("USER_PROJECT", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const ProjectDelete = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.ProjectDelete(id).then(
    () => {
      commit("DELETE_PROJECT", id);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};