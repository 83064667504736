export const SET_LOADER = (state, status) => {
	state.loader = status;
};

export const SET_DATA = (state, data) => {
	state.liveUser = data.liveUser
	state.totalUser = data.totalUser
	state.totalProject = data.totalProject
	state.liveProject = data.liveProject
};

export const SET_NULL_DATA = (state) => {
	state.liveUser = []
	state.totalUser = 0
	state.totalProject = 0
	state.liveProject = 0
}

// for only assessment
export const SET_DATA_ASSE = (state, data) => {
	state.assessments = data;
};

export const SET_NULL_DATA_ASSE = (state) => {
	state.assessments = [];
}

// for only Telemedicines
export const SET_DATA_TELE = (state, data) => {
	state.telemedicines = data;
};

export const SET_NULL_DATA_TELE = (state) => {
	state.telemedicines = [];
}

// for only eMedical
export const SET_DATA_EML = (state, data) => {
	state.eMedicalBoard = data;
};

export const SET_NULL_DATA_EML = (state) => {
	state.eMedicalBoard = [];
}

// for only Lab test
export const SET_DATA_LBT = (state, data) => {
	state.labTests = data;
};

export const SET_NULL_DATA_LBT = (state) => {
	state.labTests = [];
}

// for only Doctor
export const SET_DATA_DOC = (state, data) => {
	state.doctors = data;
};

export const SET_NULL_DATA_DOC = (state) => {
	state.doctors = [];
}

// for only patient
export const SET_DATA_PSND = (state, data) => {
	state.registeredPatients = data;
};

export const SET_NULL_DATA_PSND = (state) => {
	state.registeredPatients = [];
}

// for only patient
export const SET_DATA_PAY = (state, data) => {
	state.payments = data;
};

export const SET_NULL_DATA_PAY = (state) => {
	state.payments = [];
}

// for only subscriptionCounts
export const SET_DATA_SUB = (state, data) => {
	state.subscription = data;
};

export const SET_NULL_DATA_SUB = (state) => {
	state.subscription = [];
}