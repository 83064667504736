import { apiServices } from '../../../services/api.service';
import { successHandler, errorHandler } from '../../../services/_helper';

export const getAll = async ({ commit }, { limit, offset, keyword, status }) => {
	commit("SET_DATA_LOADER", true);
	await apiServices.getFormFiles(limit, offset, keyword, status).then(
		(response) => {
			commit("SET_DATA_LOADER", false);
			commit('SET_LOAD_MORE', response.data.total > offset + limit);
			if (+offset > 0) {
				commit('SET_POSTS2', response.data.result);
			} else {
				commit('SET_POSTS1', response.data);
			}
		},
		(error) => {
			commit("SET_DATA_LOADER", false);
			commit('SET_SETTING_NULL', [])
			errorHandler(error.response);
		}
	);
};

export const AddFormFile = async ({ commit, dispatch }, { status }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.AddFormFile(status).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			dispatch('getAll', { limit: 10, offset: 0, keyword: '', status: status })
			successHandler('Added successfully');
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};


export const setStatus = async ({ commit }, { id, status }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.setFormStatus(id, status).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			commit("UPDATE_STATUS", { id });
			successHandler("Status updated successfully");
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
export const deleteFormFile = async ({ commit }, { id }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.deleteFormFile(id).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			commit("UPDATE_STATUS", { id });
			successHandler("Delete successfully");
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};
