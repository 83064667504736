import moment from "moment";

export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};
export const SET_PROFILE = (state, data) => {
  state.createdAt = data.createdAt;
  state.createdBy = data.createdBy;
  state.email = data.email;
  state.id = data.id;
  state.keys = data.keys;
  state.lastStatus = data.lastStatus;
  state.loginId = data.loginId;
  state.name = data.name;
  state.phoneNumber = data.phoneNumber;
  state.roles = data.roles;
  state.status = data.status;
  state.totalProject = data.totalProject;
  state.updatedAt = data.updatedAt;
  state.userProject = data.userProject;

  console.log(state.userProject);
};
export const SET_DATA = (state, data) => {
  state.data = data[0];
  state.total = data[1];
};

export const PUSH_DATA = (state, data) => {
  state.data.push(...data[0]);
};

export const SET_USER_FORMDATA = (state, data) => {
  state.userFormData = data.form;
  state.excelData = [];
  data.form.forEach((item) => {
    state.excelData.push({
      // "Serial No": item.formCollection?.serialNo===item.serialNo?item.serialNo:item.formCollection?.serialNo+ '\n'+item.serialNo,
      "Serial No": item.serialNo,
      Title: item.title,
      "First Name": item.firstName,
      "Last Name": item.lastName,
      Initial: item.initial,
      // "Email": item.formCollection?.email===item.email?item.email:item.formCollection?.email+ '\n'+item.email,
      Email: item.email,
      "Father Name": item.fatherName,
      DOB: item.dob,
      Gender: item.gender,
      Profession: item.profession,
      "Mailing Street": item.mailingStreet,
      "Mailing City": item.mailingCity,
      "Mailing Postal Code": item.mailingPostalCode,
      "Mailing Country": item.mailingCountry,
      "Service Provider": item.serviceProvider,
      "File No": item.fileNo,
      "Reference No": item.referenceNo,
      "Sim No": item.simNo,
      "Type Of Network": item.typeOfNetwork,
      "Cell Model No": item.cellModelNo,
      "IMEI 1": item.imei1,
      "IMEI 2": item.imei2,
      "Type Of Plan": item.typeOfPlan,
      "Credit Card Type": item.creditCardType,
      "Contract Value": item.contractValue,
      "Date Of Issue": item.dateOfIssue,
      "Date Of Renewal": item.dateOfRenewal,
      Installment: item.installment,
      "Amount In Words": item.amountInWords,
      Remarks: item.remarks,
    });
    if (
      item.serialNo === item.formCollection?.serialNo &&
      item.title === item.formCollection?.title &&
      item.firstName === item.formCollection?.firstName &&
      item.lastName === item.formCollection?.lastName &&
      item.initial === item.formCollection?.initial &&
      item.email === item.formCollection?.email &&
      item.fatherName === item.formCollection?.fatherName &&
      item.dob === item.formCollection?.dob &&
      item.gender === item.formCollection?.gender &&
      item.profession === item.formCollection?.profession &&
      item.mailingStreet === item.formCollection?.mailingStreet &&
      item.mailingCity === item.formCollection?.mailingCity &&
      item.mailingPostalCode === item.formCollection?.mailingPostalCode &&
      item.mailingCountry === item.formCollection?.mailingCountry &&
      item.serviceProvider === item.formCollection?.serviceProvider &&
      item.fileNo === item.formCollection?.fileNo &&
      item.referenceNo === item.formCollection?.referenceNo &&
      item.simNo === item.formCollection?.simNo &&
      item.typeOfNetwork === item.formCollection?.typeOfNetwork &&
      item.cellModelNo === item.formCollection?.cellModelNo &&
      item.imei1 === item.formCollection?.imei1 &&
      item.imei2 === item.formCollection?.imei2 &&
      item.typeOfPlan === item.formCollection?.typeOfPlan &&
      item.creditCardType === item.formCollection?.creditCardType &&
      item.contractValue === item.formCollection?.contractValue &&
      item.dateOfIssue === item.formCollection?.dateOfIssue &&
      item.dateOfRenewal === item.formCollection?.dateOfRenewal &&
      item.installment === item.formCollection?.installment &&
      item.amountInWords === item.formCollection?.amountInWords
    ) {
      state.rightData += 1;
    } else {
      state.wrongData += 1;
    }
  });
};

export const PROJECT_DETAILS = (state, data) => {
  state.accountId = data.accountId;
  state.accuracy = data.accuracy;
  state.createdAt = data.createdAt;
  state.earning = data.earning;
  state.desc = data.desc;
  state.endDateEdit = moment(data.endDate).format("YYYY-MM-DDThh:mm");
  state.endDate = moment(data.endDate).format("MM/DD/YYYY hh:mm:ss a");
  state.file1 = data.file1;
  state.file2 = data.file2;
  state.file3 = data.file3;
  state.file4 = data.file4;
  state.fileName1 = data.fileName1;
  state.fileName2 = data.fileName2;
  state.fileName3 = data.fileName3;
  state.fileName4 = data.fileName4;
  state.id = data.id;
  state.level = data.level;
  state.levelObj = { id: data.level, name: data.level };
  state.overallAccuracy = data.overallAccuracy;
  state.price = data.price;
  state.quantity = data.quantity;
  state.right = data.right;
  state.skipRemaining = data.skipRemaining;
  state.skipped = data.skipped;
  state.startDateEdit = moment(data.startDate).format("YYYY-MM-DDThh:mm");
  state.startDate = moment(data.startDate).format("MM/DD/YYYY hh:mm:ss a");
  state.status = data.status;
  state.title = data.title;
  state.titleObj = { id: data.title, name: data.title };
  state.tnc = data.tnc;
  state.type = data.type;
  state.typeObj = { id: data.type, name: data.type };
  state.updatedAt = data.updatedAt;
  state.updatedBy = data.updatedBy;
  state.userDetailId = data.userDetailId;
  state.wrong = data.wrong;
};

export const SET_UPDATE_STAFF = (state, data) => {
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  state.data[objIndex].designation = data.designation;
  state.data[objIndex].username = data.username;
};
