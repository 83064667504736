import {
  mdiViewDashboard,
  mdiFolderText,
  mdiFormSelect,
} from "@mdi/js";

export default [
  "General",
  [
    {
      to: "/",
      icon: mdiViewDashboard,
      label: "Dashboard",
    },
  ],
  "LIST",
  [
    {
      icon: mdiFormSelect,
      label: "Form Files",
      to: "/form/all",
    },
    {
      to: "/user/all",
      icon: mdiFolderText,
      label: "User List",
    },
  ],
];
