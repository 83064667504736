export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};

export const SET_PROFILE = (state, data) => {
  state.accountId=data.accountId
  state.address=data.address
  state.city=data.city
  state.account=data.account
  state.accountEmail=data.account?.email
  state.accountKeys=data.account?.keys
  state.companyName=data.companyName
  state.configMail=data.configMail
  state.configMailPassword=data.configMailPassword
  state.country=data.country
  state.createdAt=data.createdAt
  state.id=data.id
  state.landmark=data.landmark
  state.locality=data.locality
  state.logo=data.logo
  state.logoName=data.logoName
  state.managerName=data.managerName
  state.phoneNumber=data.phoneNumber
  state.pincode=data.pincode
  state.state=data.state
  state.status=data.status
  state.updatedAt=data.updatedAt
  state.website=data.website
  state.whatsapp=data.whatsapp
};
