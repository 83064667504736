export default {
  data: [],
  total: 0,
  loader: false,
  loadMore:false,
  Logdata:[],
  LogdataTotal:0,
  dataLoader: false,

  id: null,
  phoneNumber: null,
  userApp: null,
  doctorApp: null,
  doctorWeb: null,
}
