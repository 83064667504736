export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};

export const SET_DATA = (state, data) => {
  state.data = data.result;
  state.total = data.total;
};

export const PUSH_DATA = (state, data) => {
  state.data.push(...data[0]);
};
export const SET_POSTS1 = (state, data) => {
  state.data = data.result;
  state.total = data.total;};

export const SET_POSTS2 = (state, data) => {
  state.data.push(...data);
};
export const UPDATE_STATUS = (state, data) => {
  state.statusModal=false
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  state.data[objIndex].status = data.status;
};

export const UPDATE_PROJECT_STATUS = (state, data) => {
  state.statusModal=false
  const objIndex = state.data.findIndex((obj) => obj.userProject[0].id === data.id);
  state.data[objIndex].userProject[0].status = data.status;
  state.data[objIndex].userProject[0].startDate = data.startTime;
  state.data[objIndex].userProject[0].endDate = data.endTime;
};
export const DELETE_PROJECT = (state, data) => {
  const objIndex = state.userProject.findIndex((obj) => obj.id === data);
  if(objIndex >=0){
    state.userProject.splice(objIndex, 1);
  }
};
export const USER_PROJECT = (state, data) => {
  state.userProject=data.result
  state.userProjectTotal=data.total
};

export const USER_PROFILE = (state, data) => {
  state.account=data.account 
  state.accountEmail=data.account.email
  state.accountKeys=data.account.keys
  state.accountWallet=data.account.wallet
  state.accountId=data.accountId
  state.createdAt=data.createdAt
  state.delay=data.delay
  state.firstName=data.firstName
  state.id=data.id
  state.lastName=data.lastName
  state.level2=data.level2
  state.level3=data.level3
  state.mobile=data.mobile
  state.profile=data.profile
  state.profileName=data.profileName
  state.updatedAt=data.updatedAt
  state.userProject=data.userProject
}

