import router from '../../router';

export function authHeader(type) {
	const token = localStorage.getItem('accessToken');
	if (token) {
		if (type === 'FormData') {
			return { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token };
		} else {
			return { Authorization: 'Bearer ' + token };
		}
	} else {
		router.push('/login');
		localStorage.clear();
	}
}
 