<template>
	<div class="notification">
		<notification-message v-if="notification" :notification="notification" />
	</div>
</template>

<script>
	import NotificationMessage from './NotificationMessage.vue';
	import { mapState } from 'vuex';
	export default {
		components: {
			NotificationMessage,
		},
		computed: mapState(['notification']),
	};
</script>

<style>
	.notification {
		position: fixed;
		bottom: 0;
		right: 0;
		margin-right: 15px;
		width: 320px;
		z-index: 999;
	}
	@media (max-width: 767px) {
		.notifications-list {
			margin-right: 5px;
			width: 250px;
			font-size: 0.9rem;
		}
	}
</style>