import { defineAsyncComponent } from 'vue';

const AsideMenu = defineAsyncComponent(() => import('../components/AsideMenu.vue'));
const AsideMenuItem = defineAsyncComponent(() => import('../components/AsideMenuItem.vue'));
const AsideMenuList = defineAsyncComponent(() => import('../components/AsideMenuList.vue'));

const BaseButtons = defineAsyncComponent(() => import('../components/BaseButtons.vue'));
const BaseButton = defineAsyncComponent(() => import('../components/BaseButton.vue'));

const CardComponent = defineAsyncComponent(() => import('../components/CardComponent.vue'));
const NewCard = defineAsyncComponent(() => import('../components/CardComponentNew.vue'));

const CardWidget = defineAsyncComponent(() => import('../components/CardWidget.vue'));
const DashboardCard = defineAsyncComponent(() => import('../components/DashboardCard.vue'));

const CheckboxCell = defineAsyncComponent(() => import('../components/CheckboxCell.vue'));
const CheckRadioPicker = defineAsyncComponent(() => import('../components/CheckRadioPicker.vue'));


const Control = defineAsyncComponent(() => import('../components/Base-Control.vue'));
const ControlIcon = defineAsyncComponent(() => import('../components/ControlIcon.vue'));

const Divider = defineAsyncComponent(() => import('../components/Base-Divider.vue'));


const Field = defineAsyncComponent(() => import('../components/Base-Field.vue'));


const FooterBar = defineAsyncComponent(() => import('../components/FooterBar.vue'));

const FullScreenSection = defineAsyncComponent(() => import('../components/FullScreenSection.vue'));

const GrowingNumber = defineAsyncComponent(() => import('../components/GrowingNumber.vue'));

const HeroBar = defineAsyncComponent(() => import('../components/HeroBar.vue'));


const Icon = defineAsyncComponent(() => import('../components/Base-Icon.vue'));

const Level = defineAsyncComponent(() => import('../components/Base-Level.vue'));
const LevelTwo = defineAsyncComponent(() => import('../components/LevelTwo.vue'));

const MainSection = defineAsyncComponent(() => import('../components/MainSection.vue'));

const ModalBox = defineAsyncComponent(() => import('../components/ModalBox.vue'));

const NavBar = defineAsyncComponent(() => import('../components/NavBar.vue'));
const NavBarItem = defineAsyncComponent(() => import('../components/NavBarItem.vue'));
const NavBarItemLabel = defineAsyncComponent(() => import('../components/NavBarItemLabel.vue'));
const NavBarMenu = defineAsyncComponent(() => import('../components/NavBarMenu.vue'));
const NavBarMenuDivider = defineAsyncComponent(() => import('../components/NavBarMenuDivider.vue'));

const Notification = defineAsyncComponent(() => import('../components/Base-Notification.vue'));
const NotificationList = defineAsyncComponent(() => import('../components/NotificationList.vue'));
const NotificationMessage = defineAsyncComponent(() => import('../components/NotificationMessage.vue'));

const Overlay = defineAsyncComponent(() => import('../components/Base-Overlay.vue'));

const spinner1 = defineAsyncComponent(() => import('../components/Base-Spinner.vue'));

// new added
const LoadBtn = defineAsyncComponent(() => import('../components/LoadingButton.vue'))

const GlobalComponents = {
	install(app) {
    app.component('aside-menu', AsideMenu);
    app.component('aside-menu-item', AsideMenuItem);
    app.component('aside-menu-list', AsideMenuList);

    app.component('base-buttons', BaseButtons);
    app.component('base-button', BaseButton);

    app.component('card-component', CardComponent);
    app.component('new-card', NewCard);

    app.component('card-widget', CardWidget);

    app.component('dashboard-card', DashboardCard);

    app.component('check-box-cell', CheckboxCell)
    app.component('check-radio-picker', CheckRadioPicker)


    app.component('base-control', Control)
    app.component('control-icon', ControlIcon)

    app.component('base-divider', Divider)


    app.component('base-field', Field)


    app.component('footer-bar', FooterBar)

    app.component('full-screen-section', FullScreenSection)

    app.component('growing-number', GrowingNumber)

    app.component('hero-bar', HeroBar)

    app.component('base-icon', Icon)

    app.component('base-level', Level)
    app.component('level-two', LevelTwo)

    app.component('main-section', MainSection)
    
    app.component('modal-box', ModalBox)

    app.component('nav-bar', NavBar)
    app.component('nav-bar-item', NavBarItem)
    app.component('nav-bar-item-label', NavBarItemLabel)
    app.component('nav-bar-menu', NavBarMenu)
    app.component('nav-bar-menu-divider', NavBarMenuDivider)

    app.component('base-Notification', Notification)
    app.component('Notification-list', NotificationList)
    app.component('Notification-message', NotificationMessage)

    app.component('base-overlay', Overlay)

    app.component('base-spinner1', spinner1);

    app.component('loading-button', LoadBtn);
  },
};

export default GlobalComponents;
